







































import Vue from "vue";
import Component from "vue-class-component";
@Component
export default class RecruiterAndResumeBlock extends Vue {
  rate = 175;
}
